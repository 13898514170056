import { publishEvent } from '@/utils/bus';
import axios from 'axios';

const httpMethods = ['get', 'post', 'put', 'delete'];

let isSPA = false;
let isAccessExpiredPublished = false;

const axiosErrorInterceptor = () => {
  return (error) => {
    if (!isSPA) return Promise.reject(error);
    const errorCodes = [405];
    const status = error.response?.status;
    const respUrl = error.response?.request.responseURL;
    const containsErrorCode = errorCodes.includes(status);
    const containsLoginUrl = respUrl?.includes('/login?destination_url');
    if (containsErrorCode && containsLoginUrl && !isAccessExpiredPublished) {
      isAccessExpiredPublished = true;
      publishEvent('access-expired');
    }
    return Promise.reject(error);
  };
};

axios.interceptors.response.use((response) => response, axiosErrorInterceptor());

const api = {
  debug: false,

  install(Vue, options = {}) {
    if (!Vue.prototype.$harbourData) {
      Vue.prototype.$harbourData = {};
    }

    httpMethods.forEach((method) => {
      Vue.prototype.$harbourData[method] = this.createApiMethod(method);
    });

    Vue.prototype.$resetAccessExpired = this.resetAccessExpired;

    if (options.spa !== undefined) {
      isSPA = options.spa;
    }
    if (options.debug !== undefined) {
      this.debug = options.debug;
    }
  },

  createApiMethod(method) {
    return async (url, payload, query, config) => {
      const data = payload || {};
      const params = query || {};
      const options = config || {};
      const headers = options.headers || {};

      const responseType = options.responseType || 'json';
      const signal = options.signal || null;
      const withCredentials =
        options.withCredentials !== undefined ? options.withCredentials : false;

      const promise = new Promise((resolve, reject) => {
        if (this.debug) {
          console.log('%cRequesting...', 'color: #ffc600;', url, data);
        }
        // Help Axios to keep error details and stack trace https://github.com/axios/axios/issues/2387
        // This error will be used if the Axios request fails
        // const axiosRequestHelperError = new Error();
        axios({
          method,
          url,
          data,
          params,
          signal,
          headers,
          responseType,
          withCredentials,
          onUploadProgress(progressEvent) {
            if (
              typeof options.onUploadProgress === 'function' &&
              progressEvent.type === 'progress'
            ) {
              options.onUploadProgress(progressEvent);
            }
          },
          onDownloadProgress(progressEvent) {
            if (
              typeof options.onDownloadProgress === 'function' &&
              progressEvent.type === 'progress'
            ) {
              options.onDownloadProgress(progressEvent);
            }
          },
        }).then(
          (res) => {
            resolve(res);
            if (this.debug) {
              console.log('%cReturning...', 'color: #42d392;', url, res.data);
            }
          },
          (error) => {
            reject(error);

            const isCanceled = axios.isCancel(error);
            if (isCanceled) {
              if (this.debug) {
                console.log('Request canceled', url);
              }
            } else {
              if (this.debug) {
                console.error('Data request error:', url, error);
              }
            }
          },
        );
      });

      return promise;
    };
  },

  resetAccessExpired() {
    isAccessExpiredPublished = false;
  },
};

export default api;
